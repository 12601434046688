import React from 'react'
import { Link } from 'gatsby'
import styled from '@emotion/styled'
import { StaticImage } from 'gatsby-plugin-image'
import { Section, AnimatedLink } from '@styles/globalComponents'

const HomeAbout = () => {
  return (
    <AboutSection>
      <ImageWrapper>
        <Link to="/about">
          <StaticImage
            src="../../static/images/olivia-rose-about-side.jpg"
            alt="About Olivia Rose"
            placeholder="blurred"
            objectFit="cover"
          />
        </Link>
      </ImageWrapper>
      <TextContainer>
        <Title>Hi, I'm Olivia</Title>
        <Description>
          I am passionate about baking and I love watching the smile on someone’s face grow when I share my creations with them! 
          This blog is a compilation of my favorite, tried and true recipes and with all my tips and tricks to help you master them. 
          I love incorporating flavors from the local ingredients of my home, Hawaii, and I hope you will love these recipes as much as I do!
        </Description>
        <AnimatedLink>
          <Link to="/about">More about me!</Link>
        </AnimatedLink>
      </TextContainer>
    </AboutSection>
  )
}

const AboutSection = styled(Section)`
  position: relative;
  margin: 2em auto;
  max-width: 450px;
  @media screen and (min-width: ${(props) => props.theme.screen.medium}) {
    max-width: 850px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`

const ImageWrapper = styled.div`
  max-width: 400px;
  margin: 0 auto;
  position: relative;
  width: 100%;
`

const TextContainer = styled.div`
  text-align: center;
  position: relative;
  background: white;
  padding: 1em 1.5em 0;
  border-radius: 6px;
  font-size: 0.9em;

  @media screen and (min-width: ${(props) => props.theme.screen.medium}) {
    transform: translate(-10%);
  }
`

const Title = styled.h3`
  font-family: ${(props) => props.theme.fonts.header};
  text-transform: uppercase;
  letter-spacing: 0.05em;
  padding: 0.8em;
  font-size: 1.5em;
  max-width: ${(props) => props.theme.sizes.maxWidthCentered};
`

const Description = styled.p`
  line-height: 1.8;
  max-width: 400px;
  margin-bottom: 2em;
  @media screen and (min-width: ${(props) => props.theme.screen.medium}) {
    text-align: left;
    margin: 0 1em 1em;
  }
`

export default HomeAbout
