import React, { useState } from 'react'
import styled from '@emotion/styled'
import { Container } from 'theme-ui'
import CategoryPosts from '@components/CategoryPosts'
import { Section, CategoryTitle } from '@styles/globalComponents'

const CategoryTabs = ({ cookies, pastries, dessert, ...props }) => {
  // this section can represent categories such as "Cookies", "Cupcakes", "Entrees"
  // passing and rendering exact posts based on tab category
  const [selectedTab, setSelectedTab] = useState('Dessert')

  return (
    <CategorySection>
      <CategoryTitle>Explore Recipes</CategoryTitle>
      <Container>
        <TabNavigation>
          <TabLink
            activeTab={selectedTab === 'Dessert' ? true : false}
            onClick={(e) => setSelectedTab(e.target.text)}
          >
            Dessert
          </TabLink>
          <TabLink
            activeTab={selectedTab === 'Pastries' ? true : false}
            onClick={(e) => setSelectedTab(e.target.text)}
          >
            Pastries
          </TabLink>
          <TabLink
            activeTab={selectedTab === 'Cookies' ? true : false}
            onClick={(e) => setSelectedTab(e.target.text)}
          >
            Cookies
          </TabLink>
        </TabNavigation>
        <TabContent>
          {selectedTab === 'Cookies' ? (
            <CategoryPosts posts={cookies} basePath={props.basePath} />
          ) : selectedTab === 'Dessert' ? (
            <CategoryPosts posts={dessert} basePath={props.basePath} />
          ) : (
            <CategoryPosts posts={pastries} basePath={props.basePath} />
          )}
        </TabContent>
      </Container>
    </CategorySection>
  )
}

const CategorySection = styled(Section)`
  display: flex;
  flex-direction: column;
  max-width: none;
  padding-left: 0;
  padding-right: 0;
`

const TabNavigation = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: -2.7rem auto;
  background: ${(props) => props.theme.colors.neutral};
  margin-top: 1em;
`

const TabLink = styled.a`
  font-size: 0.9em;
  display: inline-block;
  text-decoration: none;
  padding: 30px 8px 0;
  margin: 0 8px;
  display: inline-block;
  font-family: 'proximanova', sans-serif;
  font-size: 0.8em;
  line-height: 1.5;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  font-weight: 500;
  color: ${(props) => props.theme.colors.primary};

  &:hover {
    cursor: pointer;
    color: ${(props) => props.theme.colors.secondary};
  }

  ${(props) =>
    props.activeTab &&
    `
    &::after {
      content: "";
      display: block;
      height: 30px;
      width: 0;
      border-left: 1px solid #252121;
      margin: 0.5em auto 0;
    }`}
`
const TabContent = styled.div`
  background: ${(props) => props.theme.colors.neutral};
`

export default CategoryTabs
